import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { TimezoneApiService } from '@app/core/service/timezone-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITimezone } from '@backend/types/timezone';
import { ITenant } from '@backend/models/types/tenant';
import { IonNav, IonModal } from '@ionic/angular';
import { ToastService } from '@app/core/service/toast.service';
import { EMAIL_REGEX } from '@app/utils/email';

@Component({
  selector: 'app-tenant-edit',
  templateUrl: './tenant-edit.component.html',
  styleUrls: ['./tenant-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantEditComponent {
  @Input()
  public item: MongoStoredObject<ITenant> | null = null;

  @ViewChild('timezoneSelectorModal', { static: true })
  public timezoneSelectorModal!: IonModal;

  protected mainFormGroup: FormGroup = new FormGroup({
    tenantName: new FormControl<string>('', Validators.required),
    adminFirstName: new FormControl<string>('', Validators.required),
    adminLastName: new FormControl<string>('', Validators.required),
    adminEmail: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX)
    ]),
    timezone: new FormControl<string>('', Validators.required)
  });
  protected isBusy = false;
  protected timezones: ITimezone[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantList: TenantListService,
    private readonly _toastService: ToastService,
    private readonly _timezoneApiService: TimezoneApiService
  ) {
    this._timezoneApiService.getTimezones().subscribe((timezones) => {
      this.timezones = timezones;
    });
  }

  protected get getTzCode() {
    return this.mainFormGroup.get('timezone').value;
  }

  protected onFormSubmit() {
    this.isBusy = true;
    this._tenantList.createTenant(this.mainFormGroup.value).subscribe({
      next: () => {
        this._ionNav.pop();
        this.isBusy = false;
      },
      error: (error) => {
        this.isBusy = false;
        this._toastService.presentToast(error.message);
      }
    });
  }

  protected async onTimezoneSelect(timezone: ITimezone) {
    this.timezoneSelectorModal.dismiss();
    this.mainFormGroup.controls.timezone.setValue(timezone.tzCode);
  }

  protected onDeleteClick() {
    //TODO:
  }
}
